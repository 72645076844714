<template>
	<div class="popup active">
    	<div class="notice_popup human int">
			<div class="close" @click="$emit('close')">
				<img src="/images/close.png" alt="닫기"/>
			</div>
			<div class="tit">
				<span v-if="registerFlag == 'create'">소속사 확인</span>
				<span v-else>소속사 변경신청확인</span>
			</div>
			<div class="member" style="line-height: 40px;">
				<span class="name"><strong>{{mberNm}} (<SelectComp type="text" list="F:여,M:남" v-model="gender"/>/<SelectComp type="text" cdId="PRO102" v-model="empDivCd" />)
					</strong>님의 소속사를</span> <br />
				<span class="corp">&lsquo;{{mberCorpNm}}&rsquo; </span> 으로 <br />
				<span v-if="registerFlag == 'create'">
					회원가입신청 하시겠습니까?
				</span>
				<span v-else>
					변경신청하시겠습니까?
				</span>
				
			</div>
			<div class="select-btn">
				<div class="btn" @click="$emit('close')">취소</div>
				<div class="btn" @click="$emit('close', true)">
					<span v-if="registerFlag == 'create'">회원가입신청</span>
					<span v-else>소속사 변경신청</span>
				</div> 
			</div>
    	</div>
	</div>
</template>

<script>
   export default {
	props: { param:Object },
	data(){
		return{
			mberNm: this.param.mberNm,
			gender: this.param.gender,
			empDivCd: this.param.empDivCd,
			mberCorpDiv: this.param.mberCorpDiv,
			mberCorpNm : this.param.corpNm,
			registerFlag : this.param.registerFlag
	
		};
	},
	beforeMount(){
		if(this.mberCorpDiv == '1'){ this.mberCorpNm = '프로엔솔루션' }
		if(this.mberCorpDiv == '3'){ this.mberCorpNm = '소속없음' } 
	}

   }
</script>